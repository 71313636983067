import { Splide } from "@splidejs/splide";

/* MINI QUIZ JS */
class DropdownSelectorMiniQuiz extends HTMLElement {
  constructor() {
    super();

    this.button = this.querySelector("button");
    this.dropdown = this.querySelector(".dropdown");
    this.collectionUrl = this.getAttribute("collection-url");
    this.label = this.button.querySelector("div").textContent;
  }

  connectedCallback() {
    this.fetchFilterOptions();
  }

  value() {
    return this.dropdown.querySelector("select").value;
  }

  toggleDropdown() {
    //toggles classes
    this.dropdown.classList.toggle("!opacity-0");
    this.dropdown.classList.toggle("hidden");
    this.dropdown.classList.toggle('!max-h-0');
    this.updateState();
  }

  onDropdownChange(event) {
    //selectedOption[0] assumes only one option can be selected from the menu
    let activeLabel = "";
    if (event.target.selectedOptions) {
      activeLabel = event.target.selectedOptions[0].innerHTML;
    } else {
      activeLabel = this.label;
    }
    this.dropdown.querySelector("#active-selection>div").textContent =
      activeLabel;
    const suffix =
      event.target.id == "hair-type"
        ? "hair"
        : event.target.id == "product-type"
        ? "products"
        : "";
    this.button.querySelector("div").textContent = activeLabel + suffix;
  }

  updateState() {

    // forces focus on the select when clicking on the menu & blurs when clicking off.
    if (this.dropdown.classList.contains("hidden")) {
      this.dropdown.querySelector("select").blur();
    } else {
      this.dropdown.querySelector("select").focus();
    }
  }

  async fetchFilterOptions() {
    //get collection filter options
    let response = await fetch(this.collectionUrl);
    if (!response.ok) throw new Error(response.status);
    let text = await response.text();

    // find the filter related to this dropdown
    let htmlMarkup = new DOMParser()
      .parseFromString(text, "text/html")
      .querySelector(`label[for="${this.dropdown.id}"]`).innerHTML;

    //create an active selection div
    const activeSelection = document.createElement("div");
    activeSelection.innerHTML = this.button.innerHTML;
    activeSelection.classList.add(
      "text-brand-primary-100",
      "font-semibold",
      "flex",
      "justify-between",
      "items-center",
      "text-base",
      "gap-2",
      "pr-[18px]"
    );
    activeSelection
      .querySelector("span")
      .classList.remove("lg:w-6", "w-3", "rotate-180");
    activeSelection
      .querySelector("span")
      .classList.add("lg:w-4", "w-3", "rotate-180");
    activeSelection.id = "active-selection";
    this.dropdown.prepend(activeSelection);

    //create a container for the filters to append to
    const selectionContainer = document.createElement("div");
    selectionContainer.classList.add(
      "flex",
      "flex-col",
      "gap-3",
      "overflow-scroll",
    );
    this.dropdown.append(selectionContainer);

    // after this point the new dom takes effect.
    selectionContainer.innerHTML = htmlMarkup;

    //create and add event listeners
    const clickListeners = [["click", this.toggleDropdown.bind(this)]];
    clickListeners.forEach(([event, listener]) => {
      this.button.addEventListener(event, listener);
      activeSelection.addEventListener(event, listener);
    });

    const blurListeners = [["blur", this.toggleDropdown.bind(this)]];

    blurListeners.forEach(([event, listener]) => {
      selectionContainer
        .querySelector("select")
        .addEventListener(event, listener);
    });

    this.addEventListener("change", this.onDropdownChange);

    this.updateState();
  }
}

if (!window.customElements.get("dropdown-selector")) {
  window.customElements.define("dropdown-selector", DropdownSelectorMiniQuiz);
}

class FilteredFeatured extends HTMLElement {
  constructor() {
    super();

    this.dropdowns = this.querySelectorAll("dropdown-selector");
    this.button = this.querySelector('button[type="submit"]');
    this.reset = this.querySelector('button[type="reset"]');
    this.collectionUrl = this.getAttribute("collection-url");
    this.productCarousel = this.querySelector("#filtered-product-grid");
    this.isLoading = false;

    this.carousel = new Splide(this.productCarousel, {
      arrows: true,
      pagination: false,
      perPage: 4,
      perMove: 1,
      gap: "60px",
      arrowPath:
        "M31 20.5 10.85 40 8 37.242 25.3 20.5 8 3.758 10.85 1l17.3 16.742L31 20.5Z",
      classes: {
        arrows: "splide__arrows absolute top-1/2 -translate-y-1/2 z-10 w-full h-28",
        arrow: "splide__arrow h-10 w-10 rounded-none opacity-100 top-0",
        prev: "left-0 splide__arrow--prev bg-transparent",
        next: "right-0 splide__arrow--next bg-transparent",
      },
      breakpoints: {
        1024: {
          pagination: true,
          perPage: 2,
          gap: '24px',
          classes: {
            pagination:
              "splide__pagination filter-carousel__pagination -bottom-10",
            page: "filter-carousel-page",
          },
        },
      },
    });
  }

  connectedCallback() {
    this.button.addEventListener("click", this.onFilterSubmit.bind(this));
    this.reset.addEventListener("click", this.onResetSubmit.bind(this));
  }

  onResetSubmit() {
    this.productCarousel.innerHTML = "";

    //swap buttons
    this.button.classList.toggle("hidden");
    this.reset.classList.toggle("hidden");

    //reset carousel
    this.productCarousel.classList.add("opacity-0", "hidden");
    this.productCarousel.classList.remove("opacity-100");

    for (let dropdown of this.dropdowns) {
      const select = dropdown.querySelector("select");
      select.selectedIndex = -1;
      var event = new Event("change");
      dropdown.dispatchEvent(event);
    }
  }

  async onFilterSubmit() {
    let filtersToSubmit = [];
    //see if all filters are selected
    for (let dropdown of this.dropdowns) {
      const value = dropdown.querySelector("select").value;
      if (value != "") {
        filtersToSubmit.push(value);
        continue;
      }
    }

    //if they are construct the url and fetch products.
    if (!this.isLoading) {
      let url = this.collectionUrl + "?" + filtersToSubmit.join("&");
      let initialButton = this.button.textContent;
      try {
        this.isLoading = true;
        this.button.textContent = "Loading Results";
        let response = await fetch(url);
        if (!response.ok) throw new Error(response.status);
        let text = await response.text();

        let htmlMarkup = new DOMParser()
          .parseFromString(text, "text/html")
          .querySelector(".js-product-grid");

        //if there are more than 1 products create a carousel 1 is for mobile purposes, no need to create a carousel for 1 product
        if (htmlMarkup.childElementCount > 1) {
          const track = document.createElement("div");
          track.classList.add("splide__track");
          const list = document.createElement("div");
          list.classList.add("splide__list");
          list.innerHTML = htmlMarkup.innerHTML;
          track.append(list);
          this.productCarousel.append(track);
          this.productCarousel.classList.add("splide");

          this.carousel.mount();
        } else {
          const container = document.createElement("div");
          container.classList.add(
            "grid",
            "lg:grid-cols-4",
            "gap-[60px]",
            "grid-cols-1",
            "mx-auto"
          );
          container.innerHTML = htmlMarkup.innerHTML;
          this.productCarousel.append(container);
        }
      } catch (err) {
        console.error(err);
      }

      // This code is only excecuted if the above doesn't throw an error.
      this.productCarousel.classList.remove("hidden");
      //reveal carousel
      setTimeout(() => {
        this.productCarousel.classList.remove("opacity-0");
        this.productCarousel.classList.add("opacity-100");
      }, 100);

      //swap filter & reset buttons
      this.button.textContent = initialButton;
      this.button.classList.toggle("hidden");
      this.reset.classList.toggle("hidden");
      this.carousel.refresh();
      this.isLoading = false;
    }
  }
}

if (!window.customElements.get("filtered-featured")) {
  window.customElements.define("filtered-featured", FilteredFeatured);
}
